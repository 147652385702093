<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" v-on:close="showDismissibleAlert=false" :info="info" />
        <v-form ref="parameterForm" v-model="valid" >
            <v-card flat :color="$store.getters.getColorPalette(). background2ColorCode" :dark="$store.getters.getColorPalette().isDark" class="pa-2">
                <v-card-title class="sub-heading">{{mode}}{{$store.getters.getTextMap().energy_bill_distribution_report}}</v-card-title>
                <v-row class="mt-2">
                    <v-col>
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-text-field
                            :dark="$store.getters.getColorPalette().isDark"  
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.name"
                            dense
                            outlined
                            :counter="25"
                            :rules="nameRules"
                            :label="$store.getters.getTextMap().name"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.tariff_on"
                            :items="tariff_on_options"
                            :label="$store.getters.getTextMap().tariff_on"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col> 
                    <v-col>
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.notification_groups"
                            :items="notificationGroupList"
                            :label="$store.getters.getTextMap().notification_groups"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            multiple
                            card
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-divider  :dark="$store.getters.getColorPalette().isDark"/>
                <v-card-title class="sub-heading">{{ $store.getters.getTextMap().add_root_common_machine }}</v-card-title>
                <v-row >
                    <v-col cols="3">
                        <v-checkbox :dark="$store.getters.getColorPalette().isDark" v-model="selected" :label="$store.getters.getTextMap().automate_report" value="automate" ></v-checkbox>
                    </v-col>
                    <v-col  cols="3">
                        <v-checkbox :dark="$store.getters.getColorPalette().isDark" v-model="selectedRootMachine" :label="$store.getters.getTextMap().add_root_machine" value="root_machine" ></v-checkbox>
                    </v-col>
                    <v-col>
                        <v-checkbox :dark="$store.getters.getColorPalette().isDark"  v-model="selectedCommonMachine" :label="$store.getters.getTextMap().add_common_machine" value="common_machine" ></v-checkbox>
                    </v-col>
                </v-row>
                <v-divider  :dark="$store.getters.getColorPalette().isDark"/>
                <v-row  v-if="form.root_machine||form.common_machine" class="my-1">
                    <v-col>
                        <v-chip color="primary" class="mr-1" v-if="form.root_machine">{{$store.getters.getTextMap().root_machine}} - {{ machineName(form.root_machine)}}</v-chip>
                        <v-chip v-if="form.common_machine">{{$store.getters.getTextMap().common_machine}}  - {{ machineName(form.common_machine)}}</v-chip>
                    </v-col>
                </v-row>
                <v-divider  v-if="show_root_machine===true" :dark="$store.getters.getColorPalette().isDark"/>
                <v-row v-if="show_root_machine===true" class="mt-2">
                    <v-card-subtitle > {{form && form.root_machine?"Change":"Add"}}{{$store.getters.getTextMap().root_machine}}</v-card-subtitle>
                    <v-col>
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-model="rootWorkspace"
                            :items="rootWorkspaceOptions"
                            :label="$store.getters.getTextMap().workspace"
                            outlined
                            dense          
                            item-text="label"
                            item-value="value"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-model="form.root_machine"
                            :items="rootMachineOptions"
                            :label="$store.getters.getTextMap().root_machine"
                            outlined
                            dense
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                        ></v-select>
                    </v-col> 
                </v-row>
                <v-divider  v-if="show_common_machine===true" :dark="$store.getters.getColorPalette().isDark"/>
                <v-row v-if="show_common_machine===true" class="mt-2">
                    <v-card-subtitle > {{form && form.common_machine?"Change":"Add"}} Common Machine</v-card-subtitle>
                    <v-col>
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-model="commonWorkspace"
                            :items="commonWorkspaceOptions"
                            :label="$store.getters.getTextMap().workspace"
                            outlined   
                            dense       
                            item-text="label"
                            item-value="value"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                        :dark="$store.getters.getColorPalette().isDark" 
                        :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-model="form.common_machine"
                            :items="commonMachineOptions"
                            :label="$store.getters.getTextMap().common_machine"
                            outlined
                            dense
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                        ></v-select>
                    </v-col> 
                </v-row>
            </v-card>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                <v-btn v-if="!loading" small :color="$store.getters.getColorPalette().submitbtnColor"  @click="onSubmit">
                    {{$store.getters.getTextMap().submit}}
                </v-btn>
                <v-btn v-if="!loading" small :color="$store.getters.getColorPalette().cancelbtnColor"  @click="onReset">
                    {{$store.getters.getTextMap().cancel}}
                </v-btn>
            </v-card-actions>
        </v-form>
        <v-divider  :dark="$store.getters.getColorPalette().isDark"/>
        <v-card flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" class="pa-2 mt-2">
            <v-card-title class="sub-heading">  {{$store.getters.getTextMap().add_machines}}</v-card-title>
            <!-- <v-row class="mb-1">
                <v-spacer></v-spacer>
                <v-col align="right">
                    <v-btn v-if="this.showMachine===false"  :color="$store.getters.getColorPalette().accentCode" small outlined @click="addMachine = !addMachine">Add Machines to List</v-btn>
                </v-col>
            </v-row> -->
            <v-divider  :dark="$store.getters.getColorPalette().isDark"/>
            <v-form ref="machineListForm" v-model="machineListvalid">
            <!-- <v-form ref="machineListForm" v-model="machineListvalid" v-if="addMachine===true"> -->
                <v-row class="mt-1">
                    <v-col>
                        <v-text-field
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="label"
                            :rules="nameRules"
                            :label="$store.getters.getTextMap().label"
                            dense
                            outlined
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col v-if="showMachine===false">
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading"
                            v-model="workspace"
                            :items="workspaceOptions"
                            :label="$store.getters.getTextMap().workspace"
                            dense
                            outlined          
                            item-text="label"
                            item-value="value"
                            required
                        ></v-select>
                    </v-col>
                    <v-col v-if="showMachine===false">
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            v-if="!loading"
                            v-model="machine_form.machine_id"
                            :items="machineOptions"
                            :label="$store.getters.getTextMap().machine"
                            dense
                            outlined
                            item-text="label"
                            item-value="value"
                            required
                            :rules="objectRules"
                        ></v-select>
                    </v-col> 
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="!loading"  :color="$store.getters.getColorPalette().submitbtnColor" small  @click="addMachineToList">
                           {{$store.getters.getTextMap().submit }}
                    </v-btn>
                    <v-btn v-if="!loading" :color="$store.getters.getColorPalette().cancelbtnColor" small @click="resetMachineList">
                        {{$store.getters.getTextMap().cancel }}
                    </v-btn>
                </v-card-actions>
            </v-form>
            </v-card>
            <v-card flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().background2ColorCode" class="pa-2 mt-2">
                <v-spacer></v-spacer>
                <v-data-table
                    :headers="headers"
                    :items="allMachineList"
                    item-key="machine_id"
                    :search="search"
                    :single-expand="true"
                    show-expand
                    :expanded.sync="expanded"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :dark="$store.getters.getColorPalette().isDark" 
                    >
                    <template v-slot:top>
                        <v-toolbar flat  :dark="$store.getters.getColorPalette().isDark" >
                            <v-toolbar-title>{{$store.getters.getTextMap().machine_list }}</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                            <EBDMachineListItem :machine="item" v-on:delete="allMachines"/>
                        </td>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import EBDMachineListItem from '@/components/items/EBDMachineListItem'
export default {
    name:'CreateEnergyBillDistReport',
    props:['report','edit'],
    components:{
        InfoAlert,
        EBDMachineListItem
    },
    mounted(){
        if(this.report && this.report.report_id){
            this.form=Object.assign({},this.report)
            if(this.form.common_machine){
                this.selectedCommonMachine = 'common_machine'
                this.show_common_machine = false
                
            }
            if(this.form.root_machine){
                this.selectedRootMachine='root_machine'
                this.show_root_machine = false
            }
            this.api='updateEnergyBillDistReport'
            this.mode='Edit'
            this.showMachine=false
            this.allMachines()
        }else{
            this.api='createEnergyBillDistReport'
        }
    },
     data(){
        return {
            api:'createEnergyBillDistReport',
            mode:'Create',
            dialog:false,
            showMachine:true,
            valid:false,
            machineListvalid:false,
            loading:false,
            label:'',
            info:'',
            selected:'',
            selectedRootMachine:'',
            selectedCommonMachine:'',
            expanded: [],
            search:'',
            automate:false,
            show_root_machine:false,
            show_common_machine:false,
            showDismissibleAlert:false,
            workspace:null,
            rootWorkspace:null,
            commonWorkspace:null,
            machine:null,
            addMachine:false,
            machine_form:{
                machine_id:null
            },
            machineOptions:[],
            rootMachineOptions:[],
            commonMachineOptions:[],
            allMachineList:[],
            tariff_on_options : [
            {label:this.$store.getters.getTextMap().active_energy,value:'active_energy'},
            {label:this.$store.getters.getTextMap().apparent_energy,value:'apparent_energy'},
            ],
            nameRules: [
        v => !!v || 'Name is required',
        v => /\S+/.test(v) || 'Name is required',
        v => (v && v.length <= 25 && v.length > 0) || 'Name must be less than 25 characters',
      ],
      unitRules:[
          v => !!v || 'Unit is required',
        v => /\S+/.test(v) || 'Unit is required',
        v => (v && v.length <= 5) || 'Unit length is invalid'
      ],
      numberRules:[
          v => !!v || 'Required',
          v => /[0-9]+$/.test(v) || 'It should be a number',
          v => (v && v>0)|| 'It should be a valid number',
          v => (v && v.length <= 5 && v.length > 0) || 'Name must be less than 5 digits',
      ],
      floatRules:[
          v => !!v || 'Required',
          v => (v && !isNaN(Number(v))) || 'It should be a number',
          v => (v && v>0 )|| 'It should be more than zero',
          v => (v && v.length <= 8 && v.length > 0) || 'It must be less than 8 digits',
      ],
        msgRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 35 && v.length > 0) || 'Description must be less than 35 characters',
      ],
      selectRules:[
        v=> (v!=null) || 'Required'
      ],
      objectRules:[
        v => !!v || 'Required',
      ],
      listRules:[
        v=> !!v || 'Required',
        v => (v && v.length>0) || 'You have to select something',
       ],
      form:{
            name:null,
            tariff_on:null,
            automate:false,
            machine:null,
            root_machine:null,
            common_machine:null
            },
        headers: [
        {
            text: 'ID',
            align: 'start',
            filterable: false,
            value: 'machine_id',
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
        },
        {
            text: 'Name',
            value: 'name',
            class: " text-capitalize font-weight-black "+this.$store.getters.getColorPalette().tableHeaderColorName
        }
    ],
        }
    },
computed:{
    workspaceOptions(){
        let op=[]
        let g=this.$store.state.workspaces
        for(let i of g){
            op.push( {value:i.workspace_id, label: i.name })
        }
        return op
         },
    rootWorkspaceOptions(){
        let op=[]
        let g=this.$store.state.workspaces
        for(let i of g){
            op.push( {value:i.workspace_id, label: i.name })
        }
        return op
         },
    commonWorkspaceOptions(){
        let op=[]
        let g=this.$store.state.workspaces
        for(let i of g){
            op.push( {value:i.workspace_id, label: i.name })
        }
        return op
         },
    notificationGroupList(){
        let op=[]
        if(this.$store.state.notificationGroups && this.$store.state.notificationGroups.length >0){
            for(let i of this.$store.state.notificationGroups){
                op.push({
                    label:i.name,
                    value:i.group_id
                })
            }
        }
        return op
        },
    },
methods:{
    machineName(machine_id){
        let name=""
        if(machine_id){
            let machine_details=this.$store.getters.getMachineById(machine_id)
            if(machine_details && machine_details.name){
                name=machine_details.name
            }else{
                name = machine_id
            }
        }
        return name
    },
    allMachines(){
        let op=[]
        let payload={
                report_id:this.report.report_id
            }
            this.loading = true;
            axios.post(this.$store.state.api + "getEnergyBillMachinesByReportID", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            .then(response => {
            if (response.data.status == "success") {
                let machines=response.data.machines
                for(let i of machines){
                op.push({'machine_id':i.machine_id,'name':i.name,report_id:this.report.report_id})
            }
            this.allMachineList=op
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
            });
        },
    getMachineList(){
        let op=[]
        if(this.workspace){
            let payload={
                workspace_id:this.workspace
            }
            // console.log(payload)
            this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            .then(response => {
            if (response.data.status == "success") {
                let machines=response.data.machines
            for(let i of machines){
                if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                }
            }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
            });
        }
        this.machineOptions=op
        },
    getRootMachineList(){
        let op=[]
        if(this.rootWorkspace){
            let payload={
                workspace_id:this.rootWorkspace
            }
            // console.log(payload)
            this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            .then(response => {
            if (response.data.status == "success") {
                let machines=response.data.machines
            for(let i of machines){
                if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                }
            }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
            });
        }
        this.rootMachineOptions=op
        },
    getCommonMachineList(){
        let op=[]
        if(this.commonWorkspace){
            let payload={
                workspace_id:this.commonWorkspace
            }
            // console.log(payload)
            this.loading = true;
            axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
            .then(response => {
            if (response.data.status == "success") {
                let machines=response.data.machines
            for(let i of machines){
                if(i && i.name){
                    op.push({value:i.machine_id, label: i.name })
                }else{
                    op.push({value:i.machine_id, label: i.machine_id })
                }
            }
            } else {
                this.info = response.data.msg;
                this.showDismissibleAlert = true;
            }
            this.loading = false;
            })
            .catch(error => {
            this.info = error;
            this.showDismissibleAlert = true;
            this.loading = false;
            });
        }
        this.commonMachineOptions=op
        },
    automate_report(){
        if(this.selected === 'automate'){
            this.automate = ! this.automate
        }else{
            this.automate = ! this.automate
        }
      },
    close(param){
            this.form.list=this.form.list.filter(
            function(data){ return data.parameter != param.parameter }
        );
      },
    addMachineToList(){
        this.$refs.machineListForm.validate()
            if(this.machineListvalid){
                this.loading=true
                this.machine_form.report_id=this.report.report_id
                this.machine_form.label=this.label
                let d=Object.assign({}, this.machine_form)

                axios.post(this.$store.state.api+'addEBDMachineToList',d,{headers: {
                Authorization: 'Bearer '+ this.$store.state.jwt
                }})
                .then(response=>{
                    if(response.data.status==='success'){
                        this.loading=false
                        this.$store.dispatch('refreshEnergyBillDistReports')
                        this.allMachines()
                        this.resetMachineList()
                    }else{
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                        this.loading=false
                    }})
                .catch(err=>{
                    this.loading=false
                    console.log(err)
                });
            }
        },
    onSubmit(){
            this.$refs.parameterForm.validate()
            if(this.valid){
              this.loading=true
            //evt.preventDefault()
            //this.form.id=(new Date).getTime().toString()
            this.form.automated=this.automate
            let d=Object.assign({}, this.form)
            axios.post(this.$store.state.api+this.api,d,{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
        }})
        .then(response=>{
            if(response.data.status==='success'){
                this.$store.dispatch('refreshEnergyBillDistReports')
                // console.log("Report added "+d)
                this.onReset()
                    //this.dialog=false
                    //this.$emit('close')
            }else{
                //console.log(response.data.msg)
                this.info = response.data.msg;
                //this.info = d;
                this.showDismissibleAlert = true;
                //console.log(response.data.msg)
                this.loading=false
            }})
            .catch(err=>{
                this.loading=false
                console.log(err)
            });
                //this.$store.dispatch('addDevice', d)
            }
        },
    onReset(){
            //evt.preventDefault()
            /*this.form={
                id:null,
                msg:null,
                name:null
            }*/
            this.$refs.parameterForm.reset()
            this.dialog=false
            this.loading=false
            this.automate=false
            this.$emit('close')
        },
    resetMachineList(){
        this.addMachine=!this.addMachine
        this.machine_form = {}
        this.$refs.machineListForm.reset()


        }
    },
    watch:{
        selected(){
            this.automate_report()
        },
        selectedRootMachine(){
            this.show_root_machine = ! this.show_root_machine
            if(this.show_root_machine===false){
                this.form.root_machine = null
            }
        },
        selectedCommonMachine(){
            this.show_common_machine = ! this.show_common_machine
            if(this.show_common_machine===false){
                this.form.common_machine = null
            }
        },
        workspace(){
            this.getMachineList()
        },
        rootWorkspace(){
            this.getRootMachineList()
        },
        commonWorkspace(){
            this.getCommonMachineList()
        },
    }
}
</script>
<style scoped>
/* .v-application .headline{
  color:white;
}
.theme--light.v-card {
   background-color: #263238;
} */
</style>
<template>
    <v-container :dark="$store.getters.getColorPalette().isDark" fluid :style="{background: $store.getters.getColorPalette().background1ColorCode}">
      <v-row>
        <v-col cols="3">
          <v-card flat outlined class="widget-card-container pa-3" :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background1ColorCode}">
            <v-subheader>{{ $store.getters.getTextMap().trend_analysis_tools }}</v-subheader>
            <v-divider></v-divider>
          <v-card flat outlined class="widgetype-card-container pa-2" :dark="$store.getters.getColorPalette().isDark" :style="{'border': '0px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background1ColorCode}">
              <v-row >
                  <v-col>
                        <v-list :color="$store.getters.getColorPalette().background1ColorCode" dense>
                            <template  v-for="(item) in analysisTypeOptions">
                                <v-list-item   v-if="item.label && item.condition" :key="item.label" @click="handleAnalysisItemClick(item)">
                                    <v-list-item-action>
                                        <v-icon :style="{color:$store.getters.getColorPalette().accentCode}">{{item.icon}}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <!-- <v-btn text outlined small> -->
                                        <v-list-item-title>{{ item.label }}</v-list-item-title>
                                      <!-- </v-btn> -->
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-list>
                  </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card  flat outlined class=" widget-card-container pa-3" :dark="$store.getters.getColorPalette().isDark" :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().background1ColorCode}">
                  <component :is="getTrendAndAnalysis()"  v-on:close="dialog=false"/>
              </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
<script>
import ParameterComparisonPanel from '@/components/panels/ParameterComparisonPanel'
import ParameterHistoricalTODComparison from '@/components/panels/ParameterHistoricalTODComparison'
import ParamTimeframedPanel from '@/components/panels/ParamTimeframedPanel'
import MachineWiseComparisonPanel from '@/components/panels/MachineWiseComparisonPanel'
import DualAxisParamComparisonPanel from '@/components/panels/DualAxisParamComparisonPanel'
import ParameterTODPanel from '@/components/panels/ParameterTODPanel'
import ParameterUsageGraphByDays from '@/components/panels/ParameterUsageGraphByDays'
import ParameterStatGraphByDays from '@/components/panels/ParameterStatGraphByDays'
import ParameterGraphByDays from '@/components/panels/ParameterGraphByDays'
import DataHistorian from '@/components/panels/DataHistorian'
import LogPanel from '@/components/panels/loggers/LogPanel'
import AirQualityComparisionPanel from '@/components/panels/AirQualityComparisionPanel'
import MachineStatusBasedTimelinePanel from '@/components/panels/trend_analysis/MachineStatusBasedTimelinePanel'
import TODBillingAnalysisPanel from '@/components/panels/trend_analysis/TODBillingAnalysisPanel'

export default {
    name:'AnalysisPanel',
    components:{

        ParameterComparisonPanel,
        ParameterHistoricalTODComparison,
        ParamTimeframedPanel,
        MachineWiseComparisonPanel,
        DualAxisParamComparisonPanel,
        ParameterTODPanel,
        ParameterStatGraphByDays,
        ParameterGraphByDays,
        ParameterUsageGraphByDays,
        DataHistorian,
        AirQualityComparisionPanel,
        MachineStatusBasedTimelinePanel,
        TODBillingAnalysisPanel
    },
    data(){
        return {
            analysis_type:null,
            compMap2:{
                'parameterComparisonPanel':ParameterComparisonPanel,
                'dataHistorain':DataHistorian,
                'parameterHistoricalTODComparison':ParameterHistoricalTODComparison,
                'paramTimeframedPanel':ParamTimeframedPanel,
                'machineWiseComparisonPanel':MachineWiseComparisonPanel,
                'dualAxisParamComparisonPanel':DualAxisParamComparisonPanel,
                'parameterTODPanel':ParameterTODPanel,
                'parameterStatGraphByDays':ParameterStatGraphByDays,
                'parameterGraphByDays':ParameterGraphByDays,
                'parameterUsageGraphByDays':ParameterUsageGraphByDays,
                'logPanel':LogPanel,
                'airQualityComparisionPanel':AirQualityComparisionPanel,
                'machineStatusBasedTimelinePanel':MachineStatusBasedTimelinePanel,
                'todBillingAnalysisPanel':TODBillingAnalysisPanel
            },
            analysisTypeOptions:[
              {label:this.$store.getters.getTextMap().data_historian, value:'dataHistorain',icon:'mdi-monitor-eye',condition:this.$store.state.user},
              {label:this.$store.getters.getTextMap().timeframed_trends,value:'paramTimeframedPanel',icon:'mdi-calendar',condition:this.$store.state.user},
              {label:this.$store.getters.getTextMap().dual_axis_trend_comparison,value:'dualAxisParamComparisonPanel',icon:'mdi-drag-vertical-variant',condition:this.$store.state.user},
              {label:this.$store.getters.getTextMap().granular24_hrs_trend,value:'parameterGraphByDays',icon:'mdi-hours-24',condition:this.$store.state.user},
              {label:this.$store.getters.getTextMap().machinewise_trend,value:'machineWiseComparisonPanel',icon:'mdi-engine',condition:this.$store.state.user},
              {label:this.$store.getters.getTextMap().basic_statistical_trends,value:'parameterStatGraphByDays',icon:'mdi-trending-up',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().log_analysis,value:'logPanel',icon:'mdi-text-search',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().aqi_comparison_panel,value:'airQualityComparisionPanel',icon:'mdi-weather-windy',condition:this.$store.state.user },
                {label:this.$store.getters.getTextMap().time_of_day_trend,value:'parameterComparisonPanel',icon:'mdi-clock-time-five',condition:this.$store.state.user && this.$store.getters.getAccessByLevels(['alphaaccess'])},
                // {label:'3. Time Of Day Trends',value:'parameterTODPanel',icon:'mdi-clipboard-text-outline',condition:"$store.state.user"},
                {label:this.$store.getters.getTextMap().param_historical_comparisons,value:'parameterHistoricalTODComparison',icon:'mdi-history',condition:this.$store.state.user && this.$store.getters.getAccessByLevels(['alphaaccess'])},
                {label:this.$store.getters.getTextMap().energy_usage_trends,value:'parameterUsageGraphByDays',icon:'mdi-lightning-bolt',condition:this.$store.state.user && this.$store.getters.getAccessByLevels(['alphaaccess'])},
                {label:this.$store.getters.getTextMap().machine_status_timeline,value:'machineStatusBasedTimelinePanel',icon:'mdi-chart-timeline',condition:this.$store.state.user},
                {label:this.$store.getters.getTextMap().tod_billing,value:'todBillingAnalysisPanel',icon:'mdi-currency-rupee',condition:this.$store.state.user},
          ],
        }
    },
    methods:{
        getTrendAndAnalysis(){
            return this.compMap2[this.analysis_type]
        },
        handleAnalysisItemClick(item){
            this.analysis_type = item.value
        },
      }
}
</script>
<style scoped>
.widget-card-container {
height: 100%;
  /* max-height: 560px; */
  overflow-y: auto;
}
.widgetype-card-container {
height: 100%;
  /* max-height: 550px; */
  overflow-y: auto;
}
</style>